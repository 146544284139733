import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Privacy = () => {
    return (
        <Layout>
            <SEO title="Privacy Statement" />
            <div className="content-wrapper">
                <div className="container-fluid page-terms">
                    <div className="row">
                        <div className="col-md-9 mx-auto">
                            <h1>Privacy Policy</h1>
                            <h2>About</h2>
                            <p>We are Restorativ CIC (<strong>We</strong>) a company registered in England and Wales (No. 14772092).</p>
                            <p>This policy tells you how we collect and process the personal data of users of our website, products and services including computer or mobiles apps (<strong>You</strong>). Personal data means information about an individual from which that person can be identified. This excludes anonymous or anonymised data.</p>
                            <p>We are the data controller responsible for your personal data which includes the personal data of users who manage restorative practices, those who participate in restorative practices including children (under 18 and even under 13 years of age) which we accordingly process only with the consent of the child&rsquo;s school and/or parents.</p>
                            <p>This Privacy Policy supplements any other privacy or fair processing notices we may give you from time to time.</p>
                            <h2>How we collect personal data</h2>
                            <ul>
                                <li>We obtain information about users directly from them or organisations who have requested our services, to enable us to deliver our programmes.</li>
                                <li>Direct interactions with you, e.g., by email, telephone or filling in forms.</li>
                                <li>Automated technologies, e.g., through apps or website cookies.</li>
                            </ul>
                            <p>We are not responsible for the information that may be collected by third parties by clicking on links to third party websites, plug-ins, and applications.</p>
                            <h2>What personal data we collect</h2>
                            <h3>Restorative Practice Managers / Facilitators</h3>
                            <p>Names of user,&nbsp; display name, the organisation they work for, their position, email address, work contact details, information relating to accreditation and course attendance (where we are providing training), photographs, and video footage from users.</p>
                            <h3>Those users participating in Restorative Practices:</h3>
                            <p>Name, email address, photographs, audio, assessments, case details, and case notes</p>
                            <h3>Parents/Guardians:</h3>
                            <p>Names and contact details of parents/guardians.</p>
                            <h3>Users of our websites and apps:</h3>
                            <p>Technical data which includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website.</p>
                            <p>Data collected about website users:</p>
                            <p>Some or all of our sites use cookies to enable the site/application to function, to provide enhanced functionality or to enable a better understanding of how people use the website/application.</p>
                            <h2>How we use your personal data</h2>
                            <ul>
                                <li>Where it is necessary for Restorativ to complete our programmes and activities effectively, for example to contact you about something you have indicated an interest in, for processing payment information and administering apps.</li>
                                <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests, for example data analytics, or entering users (with or without consent) into prize draws, promotions, and competitions.</li>
                                <li>To comply with a legal obligation, such as health and safety obligations surrounding delivery of a programme.</li>
                            </ul>
                            <p>We may process your personal data for more than one lawful ground. Please contact us if you need details about the specific ground based on which we are processing your personal data in a particular instance.</p>
                            <h2>Change of purpose</h2>
                            <p>We will use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason which is compatible with the original purpose, as permitted by law.</p>
                            <p>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
                            <h2>How We Store Your Personal Data</h2>
                            <p>We have put in place reasonable measures to keep your personal data safe, as required by law.</p>
                            <p>Hard copy records containing personal data, such as order forms and registers, are stored in locked cabinets in our registered office and are not removed off-site. Such documents, or relevant personal data on such documents, will be securely destroyed at the end of the financial year.</p>
                            <p>Electronically held personal data are held in [password-protected] cloud storage, not locally on any staff laptops, which are in any event password protected and encrypted. We do not permit the use of external hard-drives, memory sticks or other devices for storing personal data. Such data is retained during the period which the activities took place and deleted at the end of the financial year.</p>
                            <h2>Sharing Personal Data</h2>
                            <p>We do not routinely share any personal data we collect except as follows:</p>
                            <ul>
                                <li>With our approved subcontractors for the purpose of data processing (list below).</li>
                                <li>Where you have consented, for example the data collected by apps is shared amongst schools and parents/guardians.</li>
                                <li>If your communication highlighted a child protection issue, or other issue that may result in police action. In such circumstances, we may be legally obliged to act.</li>
                                <li>Where necessary for us to seek professional advice such as from lawyers or insurance professionals, based in the United Kingdom.</li>
                                <li>Government or regulatory bodies where required by law.</li>
                                <li>Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy.</li>
                            </ul>
                            <h3>Our subcontractors</h3>
                            <div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><strong>Name</strong></td>
                                            <td><strong>Processing</strong></td>
                                            <td><strong>Weblink</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Amazon Web Services *</td>
                                            <td>Electronic data storage, cloud server and transmission service. Personal data is stored in EU based data centers.</td>
                                            <td><a href="https://aws.amazon.com" target="_blank" rel="noopener noreferrer">aws.amazon.com</a></td>
                                        </tr>
                                        <tr>
                                            <td>Axe Edge Limited</td>
                                            <td>Contracted developers who develop and enhance the Site and Apps</td>
                                            <td><a href="https://axeedge.design" target="_blank" rel="noopener noreferrer">axeedge.design</a></td>
                                        </tr>
                                        <tr>
                                            <td>G-Suite *</td>
                                            <td>Cloud Provider</td>
                                            <td><a href="https://gsuite.google.com" target="_blank" rel="noopener noreferrer">gsuite.google.com</a></td>
                                        </tr>
                                        <tr>
                                            <td>Heroku *</td>
                                            <td>Hosting, Electronic data storage, cloud server and transmission service. Hosting is in EU based data centers.</td>
                                            <td><a href="https://www.heroku.com" target="_blank" rel="noopener noreferrer">www.heroku.com/</a></td>
                                        </tr>
                                        <tr>
                                            <td>Restorative Justice Council</td>
                                            <td>Data analysis</td>
                                            <td>restorativejustice.org.uk</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>The subcontractors marked with an asterisk &ldquo;*&rdquo; in the above table are located outside of the UK and so their processing of your personal data may involve a transfer of data outside the UK. Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of the UK.</p>
                            <h2>Requesting Access to Your Personal Data</h2>
                            <p>You have various legal rights including the right to access personal data held about you, correct it or request that it be deleted at any time. Please see the<a href="https://ico.org.uk/" target="_blank" rel="noopener"> U.K. Information Commissioner&rsquo;s Office (ICO) website</a> for more details.</p>
                            <h2>Contact</h2>
                            <p>It is important that the personal data we hold about you remains accurate, please inform us of any changes to your personal data.</p>
                            <p>To discuss anything in this Privacy Policy or to request access to your personal data, please contact us:</p>
                            <p>Info@restorativ.co</p>
                            <p>Restorativ Community Interest CompanyThe Cirencester Hub, Royal Agricultural University, Cirencester, Gloucestershire, GL7 6JS, United Kingdom</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Privacy
